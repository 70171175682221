import { ChainId, SupportedChainsType, V2_ROUTER_ADDRESSES } from '@uniswap/sdk-core'

/**
 * Maps chain IDs to their network names
 * Used for display and configuration throughout the interface
 */
export const CHAIN_IDS_TO_NAMES = {
  [ChainId.MAINNET]: 'mainnet',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.SEPOLIA]: 'sepolia',
  [ChainId.POLYGON]: 'polygon',
  [ChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  [ChainId.CELO]: 'celo',
  [ChainId.CELO_ALFAJORES]: 'celo_alfajores',
  [ChainId.ARBITRUM_ONE]: 'arbitrum',
  [ChainId.ARBITRUM_GOERLI]: 'arbitrum_goerli',
  [ChainId.OPTIMISM]: 'optimism',
  [ChainId.OPTIMISM_GOERLI]: 'optimism_goerli',
  [ChainId.BNB]: 'bnb',
  [ChainId.AVALANCHE]: 'avalanche',
  [ChainId.BASE]: 'base',
  [ChainId.HARDHAT]: 'hardhat',
  [ChainId.HAVEN1_DEVNET]: 'haven1_devnet',
  [ChainId.HAVEN1_TESTNET]: 'haven1_testnet',
  [ChainId.HAVEN1_MAINNET]: 'haven1_mainnet',
} as const

/**
 * Chains that exist in the SDK but aren't fully supported in the UI yet
 * This helps prevent premature exposure of incomplete features
 */
// Include ChainIds in this array if they are not supported by the UX yet, but are already in the SDK.
const NOT_YET_UX_SUPPORTED_CHAIN_IDS: number[] = [
  ChainId.BASE_GOERLI,
  ChainId.ARBITRUM_SEPOLIA,
  ChainId.OPTIMISM_SEPOLIA,
]

/**
 * Type representing chains that are supported in the interface
 * Excludes chains that are in SDK but not ready for UI exposure
 */
// TODO: include BASE_GOERLI, OPTIMISM_SEPOLIA, or ARBITRUM_SEPOLIA when routing is implemented
export type SupportedInterfaceChain = Exclude<
  SupportedChainsType,
  ChainId.BASE_GOERLI | ChainId.ARBITRUM_SEPOLIA | ChainId.OPTIMISM_SEPOLIA
>

/**
 * Get the core chain name from environment variables
 * Used to determine the default chain for the application
 */
const core_chain_name = process.env.REACT_APP_CORE_SDK_CHAIN_NAME;

if(core_chain_name == null) throw new Error("REACT_APP_CORE_SDK_CHAIN_NAME cannot be undefined");

/**
 * Sets the default chain based on the configured environment
 * Falls back to HAVEN1_DEVNET if other Haven chains aren't specified
 */
export const DEFAULT_CHAIN: SupportedChainsType = 
  core_chain_name === "HAVEN1_MAINNET" ? ChainId.HAVEN1_MAINNET :
  core_chain_name === "HAVEN1_TESTNET" ? ChainId.HAVEN1_TESTNET : 
  ChainId.HAVEN1_DEVNET;

/**
 * List of chains officially supported by the interface
 * Currently only includes the default chain
 */
export const SUPPORTED_CHAINS: Array<SupportedChainsType> = [DEFAULT_CHAIN]

/**
 * Checks if the provided chainId is supported by the interface
 * @param chainId - The chain ID to check for support
 * @param featureFlags - Optional flags to override support for specific chains
 * @returns True if the chain is supported, false otherwise
 */
export function isSupportedChain(
  chainId: number | null | undefined | ChainId,
  featureFlags?: Record<number, boolean>
): chainId is SupportedInterfaceChain {
  // if (featureFlags && chainId && chainId in featureFlags) {
  //   return featureFlags[chainId]
  // }
  return !!chainId && SUPPORTED_CHAINS.indexOf(chainId) !== -1
}

/**
 * Safely converts a chain ID to a supported chain or undefined
 * @param chainId - The chain ID to convert
 * @param featureFlags - Optional flags to override support for specific chains
 * @returns The chain ID if supported, undefined otherwise
 */
export function asSupportedChain(
  chainId: number | null | undefined | ChainId,
  featureFlags?: Record<number, boolean>
): SupportedInterfaceChain | undefined {
  if (!chainId) return undefined
  if (featureFlags && chainId in featureFlags && !featureFlags[chainId]) {
    return undefined
  }
  return isSupportedChain(chainId) ? chainId : undefined
}

/**
 * Chains that support accurate gas estimations
 * Used to determine when to show gas estimates in the UI
 */
export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  ChainId.MAINNET,
  ChainId.POLYGON,
  ChainId.CELO,
  ChainId.OPTIMISM,
  ChainId.ARBITRUM_ONE,
  ChainId.BNB,
  ChainId.AVALANCHE,
  ChainId.BASE,
] as const

/**
 * @deprecated when v2 pools are enabled on chains supported through sdk-core
 * Chains that support Uniswap V2 pools
 */
export const SUPPORTED_V2POOL_CHAIN_IDS_DEPRECATED = [ChainId.MAINNET, ChainId.GOERLI] as const

/**
 * All chains that have V2 router addresses defined
 * Used to determine where V2 swaps can be performed
 */
export const SUPPORTED_V2POOL_CHAIN_IDS = Object.keys(V2_ROUTER_ADDRESSES).map((chainId) => parseInt(chainId))

/**
 * List of all testnet chains for development and testing
 * Used to apply special UI treatments for non-production environments
 */
export const TESTNET_CHAIN_IDS = [
  ChainId.GOERLI,
  ChainId.SEPOLIA,
  ChainId.POLYGON_MUMBAI,
  ChainId.ARBITRUM_GOERLI,
  ChainId.OPTIMISM_GOERLI,
  ChainId.CELO_ALFAJORES,
  ChainId.HARDHAT,
  ChainId.HAVEN1_DEVNET,
  ChainId.HAVEN1_TESTNET,
] as const

/**
 * All the chain IDs that are running the Ethereum protocol.
 * These are the base layer blockchains (Layer 1)
 */
export const L1_CHAIN_IDS = [
  ChainId.MAINNET,
  ChainId.GOERLI,
  ChainId.SEPOLIA,
  ChainId.POLYGON,
  ChainId.POLYGON_MUMBAI,
  ChainId.CELO,
  ChainId.CELO_ALFAJORES,
  ChainId.BNB,
  ChainId.AVALANCHE,
  ChainId.HAVEN1_MAINNET,
] as const

export type SupportedL1ChainId = (typeof L1_CHAIN_IDS)[number]

/**
 * Layer 2 chains that build on top of L1 chains
 * These networks typically offer faster and cheaper transactions
 * with immediate confirmation compared to their L1 counterparts
 */
export const L2_CHAIN_IDS = [
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.OPTIMISM,
  ChainId.OPTIMISM_GOERLI,
  ChainId.BASE,
] as const

export type SupportedL2ChainId = (typeof L2_CHAIN_IDS)[number]

/**
 * Get the priority of a chainId based on its relevance to the user.
 * Used for sorting chains in UI displays, with lower numbers showing first
 * 
 * @param {ChainId} chainId - The chainId to determine the priority for.
 * @returns {number} The priority of the chainId, the lower the priority, the earlier it should be displayed, with base of MAINNET=0.
 */
export function getChainPriority(chainId: ChainId): number {
  switch (chainId) {
    case ChainId.MAINNET:
    case ChainId.GOERLI:
    case ChainId.SEPOLIA:
      return 0
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARBITRUM_GOERLI:
      return 1
    case ChainId.OPTIMISM:
    case ChainId.OPTIMISM_GOERLI:
      return 2
    case ChainId.POLYGON:
    case ChainId.POLYGON_MUMBAI:
      return 3
    case ChainId.BASE:
      return 4
    case ChainId.BNB:
      return 5
    case ChainId.AVALANCHE:
      return 6
    case ChainId.CELO:
    case ChainId.CELO_ALFAJORES:
      return 7
    case ChainId.HARDHAT:
    case ChainId.HAVEN1_DEVNET:
    case ChainId.HAVEN1_TESTNET:
    case ChainId.HAVEN1_MAINNET:
      return 8
    default:
      return 9
  }
}

/**
 * Checks if a chain supports UniswapX protocol features
 * Currently only enabled on Ethereum mainnet
 * 
 * @param chainId - The chain ID to check for UniswapX support
 * @returns True if UniswapX is supported on the chain
 */
export function isUniswapXSupportedChain(chainId: number) {
  return chainId === ChainId.MAINNET
}
