interface Token {
  name: string
  address: string
}

interface TokenPair {
  token1: Token
  token2: Token
}

// Mainnet pairs (chainId: 8811)
const MAINNET_NO_FEE_PAIRS: TokenPair[] = [
  {
    token1: { name: "hUSDT", address: "0xEdBfAAb2C11D5a7485Ea7F765023C6988e90ABBE" },
    token2: { name: "hUSDC", address: "0x54F8F28ED3489416d3B05ccAf8d5DDaCddc495bb" }
  },
  {
    token1: { name: "hsETH", address: "0xaD3986688caa696af89A82656E102629eC98645F" },
    token2: { name: "hETH", address: "0x9E33501440DDCA26b6649D79fDb06FC6b413ceB0" }
  },
  {
    token1: { name: "hsETH", address: "0xaD3986688caa696af89A82656E102629eC98645F" },
    token2: { name: "hcbETH", address: "0x27f664485b8488e57be3c2Bda11F5AA1F570d859" }
  },
  {
    token1: { name: "hcbETH", address: "0x27f664485b8488e57be3c2Bda11F5AA1F570d859" },
    token2: { name: "hETH", address: "0x9E33501440DDCA26b6649D79fDb06FC6b413ceB0" }
  },
  {
    token1: { name: "T1", address: "0xc550955Fc189E7b3863217D7348cc315412183ba" },
    token2: { name: "T2", address: "0xF18A676d7A14208478950D1dbB3AFd1C44A63264" }
  }
]

// Testnet pairs (chainId: 810)
const TESTNET_NO_FEE_PAIRS: TokenPair[] = [
  {
    token1: { name: "hUSDT", address: "0x3F4b531b19f176Ef05258c33974aCF1Bc2C708E4" },
    token2: { name: "hUSDC", address: "0x73dBaF6987B8B37cF201d20Db86AD48B89641017" }
  },
  {
    token1: { name: "hsETH", address: "0x808422B173E408D992d30071e80253d047BfEc63" },
    token2: { name: "hETH", address: "0xd6e17Ea2F27E1ad83fD41AC5D1A3cb96CA5FC0Ac" }
  },
  {
    token1: { name: "hsETH", address: "0x808422B173E408D992d30071e80253d047BfEc63" },
    token2: { name: "hcbETH", address: "0x5F5D88D6cD5A772E82403b346754B7a0Cf535576" }
  },
  {
    token1: { name: "hcbETH", address: "0x5F5D88D6cD5A772E82403b346754B7a0Cf535576" },
    token2: { name: "hETH", address: "0xd6e17Ea2F27E1ad83fD41AC5D1A3cb96CA5FC0Ac" }
  }
]

// Staging pairs (chainId: 8110)
const STAGING_NO_FEE_PAIRS: TokenPair[] = [
  {
    token1: { name: "hUSDT", address: "0x3F4b531b19f176Ef05258c33974aCF1Bc2C708E4" },
    token2: { name: "hUSDC", address: "0x73dBaF6987B8B37cF201d20Db86AD48B89641017" }
  }
]

export function getNoFeePairs(chainId: string | undefined): Set<string> {
  const pairs = chainId === '810' ? TESTNET_NO_FEE_PAIRS : 
                chainId === '8110' ? STAGING_NO_FEE_PAIRS : 
                chainId === '8811' ? MAINNET_NO_FEE_PAIRS : []

  const pairSet = new Set(
    pairs.map(pair => {
      const addresses = [pair.token1.address, pair.token2.address].sort()
      return addresses.join('-')
    })
  )

  return pairSet
} 