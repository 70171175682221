/**
 * useTokenToUSDPrice.ts
 * 
 * This file provides a hook for converting any token's value to USD price
 * using USDC as a proxy by simulating a trade through the routing API.
 */
import { Currency, CurrencyAmount, Price, Token, TradeType } from '@uniswap/sdk-core'
import { useMemo } from 'react'
import { INTERNAL_ROUTER_PREFERENCE_PRICE, TradeState } from 'state/routing/types'
import { useRoutingAPITrade } from 'state/routing/useRoutingAPITrade'

// Create USDC token instance based on environment with fallbacks
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID ?? '810'
const USDC_ADDRESS = process.env.REACT_APP_USDC_ADDRESS

// Only create USDC token if we have valid address
const USDC = USDC_ADDRESS ? new Token(
  parseInt(CHAIN_ID),
  USDC_ADDRESS,
  6, // USDC has 6 decimal places
  'hUSDC',
  'Haven USDC'
) : undefined

// Use a larger amount for better price accuracy (1 million USDC)
// Large amounts provide more accurate price quotes due to better routing and reduced impact of fees
const USDC_AMOUNT = USDC ? CurrencyAmount.fromRawAmount(USDC, 1_000_000_000_000) : undefined // 1M USDC

/**
 * Returns the USD value (using USDC as proxy) for a given token by simulating a trade
 * This works by calculating how much of the input token you would get for 1M USDC,
 * which gives us the relative price between the token and USDC.
 * 
 * @param currency The currency to get the USD price for
 * @returns An object containing:
 *   - data: The USD price as a number (or undefined if not available)
 *   - isLoading: Boolean indicating if the price data is still loading
 */
export function useTokenToUSDPrice(currency?: Currency): {
  data?: number
  isLoading: boolean
} {
  // Return early if USDC token is not configured
  // This prevents errors when environment variables aren't properly set
  if (!USDC || !USDC_AMOUNT) {
    return { data: undefined, isLoading: false }
  }

  // Skip the API call if currency is USDC or undefined
  // No need to calculate conversion if it's already in USDC
  const shouldSkip = !currency || currency.equals(USDC)

  // Get trade information from the routing API
  // This simulates exchanging USDC_AMOUNT for the target currency
  const { trade, state } = useRoutingAPITrade(
    shouldSkip,
    TradeType.EXACT_INPUT,
    USDC_AMOUNT,
    currency,
    INTERNAL_ROUTER_PREFERENCE_PRICE // Use price-optimized routing preference
  )

  return useMemo(() => {
    // If currency is USDC, price is 1 by definition
    if (currency?.equals(USDC)) {
      return { data: 1, isLoading: false }
    }

    // Return undefined while loading to indicate data isn't ready
    if (state === TradeState.LOADING) {
      return { data: undefined, isLoading: true }
    }

    // If we have a valid trade with both input and output amounts
    if (trade && trade.outputAmount && trade.inputAmount) {
      // Calculate price: USDC per token
      // We divide the USDC amount by the token amount to get price per token
      const inputUSDC = parseFloat(trade.inputAmount.toExact())
      const outputToken = parseFloat(trade.outputAmount.toExact())
      const pricePerToken = inputUSDC / outputToken // USDC per token (USD price)
      return { data: pricePerToken, isLoading: false }
    }

    // Return undefined for the data if no valid trade exists
    // This happens if the API call fails or returns invalid data
    return { data: undefined, isLoading: false }
  }, [currency, state, trade]) // Recalculate when currency, API state, or trade changes
}