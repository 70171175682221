import { Trans } from "@lingui/macro";
import { ButtonLight, ButtonPrimary } from "components/Button";
import { StyledConnectButton, Web3StatusConnectWrapper } from "components/Web3Status";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react'
import { AccountManagerContract, ProofOfIdentityContract } from '@haven1/blockchain-sdk/contract';
import { JsonRpcProvider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers/lib/ethers'

export function useAccountStatus(address?: string): { isLoading: boolean, isVerified: boolean, isSuspended: boolean } {
    const [isLoading, setLoading] = useState(true);
    const [isVerified, setVerified] = useState(false);
    const [isSuspended, setSuspended] = useState(false);

    const accountManagerContract = AccountManagerContract.connect(
        process.env.REACT_APP_ACCOUNT_MANAGER_ADDRESS! as string,
        new JsonRpcProvider(process.env.REACT_APP_RPC_URL as string)
    );

    const poiContract = ProofOfIdentityContract.connect(
        process.env.REACT_APP_PROOF_OF_ID_CONTRACT_ADDRESS! as string,
        new JsonRpcProvider(process.env.REACT_APP_RPC_URL as string)
    );

    useEffect(() => {
        async function fetchStatus() {
            if (!address) return;
            setLoading(true);   

            const status = await accountManagerContract.getAccountStatus(address);
            if (!status.eq(2)) {
                setVerified(false);
                setSuspended(false);
                setLoading(false);
                return;
            }

            const balance = await poiContract.balanceOf(address);
            if (balance.isZero()) {
                setVerified(false);
                setSuspended(false);
                setLoading(false);
                return;
            }

            const isSuspended = await poiContract.isSuspended(address);
            setVerified(true);
            setSuspended(isSuspended);
            setLoading(false);
        }

        fetchStatus();
    }, [address]);

    return { isLoading, isVerified, isSuspended };
}

export const verifyLink = process.env.REACT_APP_VERIFY_LINK! as string

export function VerifyAccountButtonLight() {
    const { account } = useWeb3React()
    const { isSuspended: suspended, isLoading: isSuspendedLoading } = useAccountStatus(account);
    return (
        <Link
            to={(suspended || isSuspendedLoading) ? "" : verifyLink}
            target={(suspended || isSuspendedLoading) ? "_blank" : "self"}
            style={{
                textDecoration: "none",
                pointerEvents: suspended || isSuspendedLoading ? "none" : "auto",
                cursor: suspended || isSuspendedLoading ? "not-allowed" : "pointer"
            }}
        >
            <ButtonLight onClick={() => {}} fontWeight={535} $borderRadius="16px" disabled={suspended || isSuspendedLoading}>
                <Trans>{isSuspendedLoading ? "Loading..." : suspended ? "Account Suspended" : "Verify account"}</Trans>
            </ButtonLight>
        </Link>
    );
}

export function VerifyAccountButtonPrimary() {
    const { account } = useWeb3React()
    const { isSuspended: suspended, isLoading: isSuspendedLoading } = useAccountStatus(account);
    return (
        <Link
            to={(suspended || isSuspendedLoading) ? "" : verifyLink}
            target={(suspended || isSuspendedLoading) ? "_blank" : "self"}
            style={{
                textDecoration: "none",
                pointerEvents: suspended || isSuspendedLoading ? "none" : "auto",
                cursor: suspended || isSuspendedLoading ? "not-allowed" : "pointer"
            }}
        > 
            <ButtonPrimary
                style={{ marginTop: '2em', marginBottom: '2em', padding: '8px 16px' }}
                onClick={() => {}}
                disabled={suspended || isSuspendedLoading}
            >
                <Trans>{isSuspendedLoading ? "Loading..." : suspended ? "Account Suspended" : "Verify account"}</Trans>
            </ ButtonPrimary>
        </Link>
    );
}

export function VerifyAccountButton() {
    const { account } = useWeb3React()
    const { isSuspended: suspended, isLoading: isSuspendedLoading } = useAccountStatus(account);
    return (
        <Web3StatusConnectWrapper
            tabIndex={0}
            onKeyPress={(e) => e.key === 'Enter'}
            onClick={() => {}}
         >
            <Link
                to={(suspended || isSuspendedLoading) ? "" : verifyLink}
                target={(suspended || isSuspendedLoading) ? "_blank" : "self"}
                style={{
                    textDecoration: "none",
                    pointerEvents: suspended || isSuspendedLoading ? "none" : "auto",
                    cursor: suspended || isSuspendedLoading ? "not-allowed" : "pointer"
                }}
            > 
                <StyledConnectButton tabIndex={-1} data-testid="navbar-connect-wallet" style={{padding: "20px"}} disabled={suspended || isSuspendedLoading}>
                    <Trans>{isSuspendedLoading ? "Loading..." : suspended ? "Account Suspended" : "Verify account"}</Trans>
                </StyledConnectButton>
            </Link>
        </Web3StatusConnectWrapper>
    );
}